export function countDown(cb, selector) {
    const renderLoop = () => {
       const countDownDate = new Date('nov 21, 2020, 20:00:00').getTime();
        const now = new Date().getTime();
        const distance = countDownDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const milliseconds = Math.floor((distance % (1000 * 60)));

        const timerElement = document.querySelector(selector);
        if (timerElement) {
            timerElement.innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s ${milliseconds}ms`;
        }

        if (distance <= 0) {
            return cb();
        }
        window.requestAnimationFrame(renderLoop);
    }
    renderLoop();
}
