const btns = [
    { current: 'cookie_button', next: 'start', prev: 'cookies' },
    { current: 'laptop_button', next: 'laptop_one', prev: 'start' },
    { current: 'laptop_one_button', next: 'laptop_two', prev: 'laptop_one' },
    { current: 'laptop_two_button', finish: true, next: 'pop-up', prev: 'laptop_two' },
    { current: 'handy_button', next: 'handy_one', prev: 'start' },
    { current: 'handy_one_button', next: 'handy_two', prev: 'handy_one' },
    { current: 'handy_two_button', next: 'handy_three', prev: 'handy_two' },
    { current: 'handy_three_button', next: 'handy_four', prev: 'handy_three' },
    { current: 'handy_four_button', next: 'handy_five', prev: 'handy_four' },
    { current: 'handy_five_button', finish: true, next: 'handy_five', prev: 'pop-up' },
    { current: 'handy_vr_button', next: 'handy_vr_one', prev: 'start' },
    { current: 'handy_vr_one_button', next: 'handy_vr_two', prev: 'handy_vr_one' },
    { current: 'handy_vr_two_button', next: 'handy_vr_three', prev: 'handy_vr_two' },
    { current: 'handy_vr_three_button', next: 'handy_vr_four', prev: 'handy_vr_three' },
    { current: 'handy_vr_four_button', next: 'handy_vr_five', prev: 'handy_vr_four' },
    { current: 'handy_vr_five_button', finish: true, next: 'pop-up', prev: 'handy_vr_five' },
];

for (let btn of btns) {
    const toSelect = document.getElementById(btn.current);
    if (toSelect) {
        toSelect.onclick = () => {
            document.querySelector('.pop-up-close').style.display = 'block';
            const nextElem = document.getElementById(btn.next);
            const prevElem = document.getElementById(btn.prev);

            if (btn.finish) {
                nextElem.style.display = 'none';
            } else {
                nextElem.style.display = 'block';
            }
            prevElem.style.display = 'none';
        }
    }
}

const closeBtn = document.querySelector('.pop-up-close');
if (closeBtn) {
    closeBtn.onclick = () => {
        document.querySelector('.pop-up').style.display = 'none';
    }
}