import { TimelineLite, Power2 } from 'gsap/all';

// header link animation
const elements = Array.from(document.querySelectorAll('.nav span, .back span, .kollektion span'));
elements.forEach(function(element) {
  element.addEventListener('mouseover', onMouseOver);
});


function onMouseOver(event) {
  var trg = event.target;
  var tl = new TimelineLite();
  tl.to(trg, 1, {yPercent:-50, ease:Power2.easeInOut});
  tl.to(trg, 1, {yPercent:0, ease:Power2.easeInOut});
};
