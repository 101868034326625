import { countDown } from './timer.js';
import './nav.js';
import './kollektionen.js';
import './show.js';

const tilt1 = document.querySelector('#tilt');
const tilt2 = document.querySelector('#tilt_two');
const bg = document.querySelector('.bg');
const nav = document.querySelector('.nav');

window.addEventListener('mousemove', handleMove);
function handleMove(event) {
    if (!bg) return;

    const defaultTransform = 'perspective(500px) scale(1)';

    const xRot = -5 * ((event.clientY - bg.clientWidth/2) / bg.clientWidth);
    const yRot =  5 * ((event.clientX - bg.clientHeight/2) / bg.clientHeight);

    const transform1 = `transform: translate3d(0, 0, -100px) ${defaultTransform + ` rotateX(${xRot}deg)` + ` rotateY(${yRot}deg)`};`;
    tilt1.setAttribute('style', transform1);

    const transform2 = `transform: translate3d(0, 0, -200px) ${defaultTransform + ` rotateX(${-1*xRot}deg)` + ` rotateY(${-1*yRot}deg)`};`;
    tilt2.setAttribute('style', transform2);
}

countDown(() => {
    nav.style.display = 'flex'
    const timer = document.querySelector('.timer')
    if (timer) {
        timer.style.display = 'none';
    }
}, '.timer');
